.header {
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: var(--main-color);
}

.header__title {
  font-size: clamp(2rem, 5vw, 5rem);
  line-height: 2.5rem;
  font-weight: bold;
  color: whitesmoke;
  text-align: center;
  align-self: center;
  padding: 40px;
}

.header__title small {
  font-size: 60%;
  margin-left: 5px;
}

@media (orientation: landscape) {
  .header {
    position: relative;
    height: 90vh;
  }

  .header__title {
    text-align: start;
    position: absolute;
    left: 0;
    top: 0;
  }
}
