.projects {
  padding: var(--padding-md);
}

.projects::before {
  content: '';
}

@media screen and (max-width: 768px) {
  .projects {
    padding: var(--padding-sm);
  }
}
