.contact {
  padding: var(--padding-md);
  position: relative;
}

.contact__text {
  font-weight: 400;
  text-align: center;
  font-size: 1.3rem;
}

.contact__link {
  text-decoration: none;
  font-weight: bold;
  font-size: 110%;
  color: var(--main-color);
}

@media screen and (max-width: 768px) {
  .contact {
    padding: var(--padding-sm);
  }

  .contact__text {
    font-size: 1rem;
  }
}
