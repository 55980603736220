.project {
  display: grid;
  grid-template: auto 1fr auto / 1fr 1fr;
  margin-bottom: 6rem;
  gap: 1.5rem;
}

.project--left {
  grid-template-areas:
    'picture title'
    'picture info'
    'picture links';
}

.project--right {
  grid-template-areas:
    'title picture'
    'info picture'
    'links picture';
}

.project__title {
  text-align: center;
  align-self: flex-end;
  letter-spacing: 3px;
  text-transform: uppercase;
  font-size: 1.5rem;
  grid-area: title;
}

.project__picture {
  grid-row: span 3;
  grid-area: picture;
}

.project__info {
  grid-area: info;
}

.project__links {
  grid-area: links;
  display: flex;
  justify-content: space-around;
}

@media screen and (max-width: 1023px) {
  .project {
    grid-template: repeat(4, auto) / 1fr;
    grid-template-areas: 'title' 'picture' 'info' 'links';
    margin-bottom: 4rem;
  }
}
