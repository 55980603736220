* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
  overflow-x: hidden;
}

body {
  font-family: 'Open Sans', Roboto, sans-serif;
  color: #222222;
}

h2 {
  font-size: 2rem;
  margin-bottom: 1rem;
  text-align: center;
}
