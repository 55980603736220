.picture {
  display: flex;
  align-items: flex-end;
}

.picture--left {
  justify-content: flex-start;
}

.picture--right {
  justify-content: flex-end;
}

.picture__image {
  width: clamp(500px, 65%, 100%);
  height: auto;
}

.picture__image--full {
  width: 100%;
  height: auto;
}
