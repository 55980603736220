.about {
  padding: var(--padding-md);
  position: relative;
}

.about::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  border-right: 0 solid transparent;
  border-left: 100vw solid transparent;
  border-top: 40vh solid var(--main-color);
}

.about__text {
  font-weight: 400;
  text-align: center;
  font-size: 1.3rem;
}

@media screen and (max-width: 768px) {
  .about {
    padding: var(--padding-sm);
  }

  .about__text {
    font-size: 1rem;
  }
}
