.info {
  display: grid;
  grid-template: repeat(3, auto) / 1fr;
  gap: 15px 0;
}

.info__year {
  font-size: 1.2rem;
  font-weight: 300;
}

.info__description {
  grid-column: span 2;
}

.info__tecnologies {
  grid-column: span 2;
}
